<template>
  <!-- eslint-disable max-len -->
  <div class="relative h-full">
    <Sidenav
      @sidenavOpen="handleSidenavOpen"
      @onChangeRetailer="handleChangeRetailer"
      :opened="sidenavOpen"
    />
    <div class="relative lg:ml-64 flex flex-col min-h-full">

      <!-- Filter -->
    <div class="relative" :class="openConfigDrawer ? 'translate-x-0' : 'translate-x-full'">
      <div
        class="fixed top-0 left-0 bottom-0 right-0 bg-black transition-all duration-500 z-30"
        :class="!openConfigDrawer ? 'invisible opacity-0' : 'visible opacity-60'"
      ></div>
    </div>

      <Topbar @sidenavOpen="handleSidenavOpen" pageName="A receber"/>
      <header class="border-b-2 border-gray-200 bg-white">
        <div class="flex flex-col lg:flex-row gap-4 lg:gap-5 px-4 lg:px-6 pt-3 pb-4 lg:pb-5">
          <div class="flex flex-grow items-center">
            <div class="flex-shrink-0">
              <img class="w-btn mr-3" src="brand-icon-dollar.svg" />
            </div>
            <h1 class="font-bold text-xl">Repasses</h1>
          </div>
        </div>
        <nav>
          <ul class="tabs px-4 lg:px-6">
            <li class="tab-item">
              <a href="repasses" class="tab-link"> Recebidos </a>
            </li>
            <li class="tab-item is-active">
              <a href="repasses-pendentes" class="tab-link"> A receber </a>
            </li>
          </ul>
        </nav>
      </header>

      <!-- No transfers found -->
        <main v-if="!pendingTransfers">
          <NoTransferArea />
        </main>

        <div v-if="pendingTransfers" class="flex flex-shrink-0 justify-between gap-3 py-5 px-3 lg:px-6 overflow-x-auto lg:overflow-visible sticky top-0 z-10 bg-white">
          <div class="flex flex-grow gap-5">
            <div class="w-full lg:w-72">
            <div class="input-group has-icon">
              <div class="input-control">
                <div class="input-icon">
                  <SearchIcon size="24" />
                </div>
                <input
                  v-on:keyup.enter="filterBySearch('enter')"
                  v-on:keyup="waitTypingFinish"
                  v-model="searchInput"
                  type="text"
                  class="input-field"
                  placeholder="Buscar nº pedido"
                />
              </div>
            </div>
          </div>
          </div>

        <!-- Calendar and filter icons -->
          <div class="hidden lg:block flex-shrink-0">
            <div class=" flex gap-5">
              <DatePicker
                class="w-80"
                v-model="selectedDates"
                @input="handleDatePick"
                :defaultDate="defaultDate"
                placeholder="Selecionar"
                mode="range"
                maxDate="today"
                icon
                white
              />
            </div>
          </div>

          <div class="flex gap-3 flex-shrink-0 lg:hidden">
            <button class="button button-icon" id="datePickerToggle">
              <CalendarIcon size="24"/>
            </button>
          </div>
            <!-- End of calendar and filter icons -->

        </div>

        <main v-if="pendingTransfers" class="relative lg:px-6 flex-grow overflow-x-auto">
          <!-- Loading component -->
          <div
          class="bg-white bg-opacity-90 absolute top-0 right-0 left-0 bottom-0 z-10 flex flex-col items-center pt-36"
          :class="{ hidden: !searchingTransfers }"
          >
            <svg
              role="status"
              class="w-12 h-12 mr-2 text-gray-300 animate-spinner"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#20627A"
              ></path>
            </svg>
          </div>
          <table class="table">
            <thead class="text-gray-500 text-left">
              <tr>
                <th scope="col">
                  <span class="align-middle">Pedido</span>
                </th>
                <th scope="col" class="cursor-pointer" @click="handleSortTransfers('createdAt')">
                  <img class="inline-block -ml-5 mr-1" :src="handleSortTransfersIcon('createdAt')" />
                  <span class="align-middle">Data da compra</span>
                </th>
                <th scope="col" class="cursor-pointer" @click="handleSortTransfers('status')">
                  <img class="inline-block -ml-5 mr-1" :src="handleSortTransfersIcon('status')"  />
                  <span class="align-middle">Status</span>
                </th>
                <th scope="col" class="cursor-pointer text-right" @click="handleSortTransfers('totalAmount')">
                  <img class="inline-block mr-1" :src="handleSortTransfersIcon('totalAmount')"  />
                  <span class="align-middle">Valor</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(transfer, index) in pendingTransfers" :key="index">
                <td class="whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                      <img class="w-btn" :src=getImageUrl(transfer) alt="" />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm text-gray-900">
                        <span class="text-sm font-semibold text-gray-900"
                          >{{ productsNameHandler(transfer) }}</span
                        >
                      </div>
                      <div class="text-xs text-gray-500">{{ transfer.orderId }}</div>
                    </div>
                  </div>
                </td>
                <td class="">
                  <div class="text-sm text-gray-900">{{ transfer.createdAt | dateFilter }}</div>
                  <div class="text-xs text-gray-500">
                    {{ transfer.createdAt | hourFilter }}
                  </div>
                </td>
                <td class="">
                  <Badge :kind="badgeKindStatus[transfer.status] || 'default'">
                  {{ statusOptions[transfer.status] }}
                  </Badge>
                </td>
                <td class="text-right"> {{ transfer.value | moneyFilter  }}</td>
              </tr>
              <tr v-if="pendingTransfers.length === 0">
                <td colspan="100%" class="text-center">
                  <div class="py-4">Nenhum resultado encontrado</div>
                </td>
              </tr>
            </tbody>
          </table>
        </main>

        <footer v-if="pendingTransfers.length !== 0" class="bg-white lg:sticky lg:bottom-0 z-9 lg:px-6">
          <div class="flex justify-end gap-2 bg-gray-100 px-4 py-2">
            <div class="text-gray-500 text-sm">
              Total a receber:
            </div>
            <div class="font-semibold text-right text-sm">
               {{ totalAmount | moneyFilter }}
            </div>
          </div>
          <Pagination
            v-if="totalAmount !== 0"
            v-model="currentPage"
            :itemsPerPage="transfersPerPage"
            :numberOfItems="totalOfPendingTransfers"
            @pagination="fillTransfers"
          />
        </footer>
    </div>
  </div>
</template>

<script>
import { SearchIcon, CalendarIcon } from '@vue-hero-icons/outline';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import StringMixin from '@/mixins/string';
import {
  DatePicker,
  Badge,
  Pagination,
} from '@/components';

import Sidenav from '../../Sidenav.vue';
import Topbar from '../../Topbar.vue';
import NoTransferArea from './NoTransferArea.vue';

export default {
  name: 'transfers-pending',
  metaInfo: {
    title: 'Repasses a receber',
  },
  mixins: [StringMixin],
  components: {
    Badge,
    Pagination,
    SearchIcon,
    Sidenav,
    Topbar,
    DatePicker,
    CalendarIcon,
    NoTransferArea,
  },
  data: () => ({
    sidenavOpen: false,
    openModal: false,
    selectedDates: [],
    searchingTransfers: false,
    searchInput: '',
    sort: { createdAt: -1 },
    filterStatusSelected: '',
    defaultDate: [],
    showFilters: false,
    channelSelected: '',
    openConfigDrawer: false,
    currentSearchTimeout: null,
    maxSearchTimeout: 800,
    totalFinalAmount: 0,
    badgeKindStatus: {
      ABANDONED: 'danger',
      CANCELED: 'danger',
      CLOSED: 'default',
      OPEN: 'success',
      PENDING: 'warning',
    },
    statusOptions: {
      '': 'Todos',
      ABANDONED: 'Expirado',
      CANCELED: 'Cancelado',
      CLOSED: 'Fechado',
      OPEN: 'Contratado',
      PENDING: 'Pendente',
    },
    channelOptions: {
      '': 'Todos',
      paymentLink: 'Link de pagamento',
      checkout: 'Checkout',
      pdp: 'Pdp',
      site: 'Site',
      imobiliaria: 'Imobiliária',
    },
    transfersPerPage: 15,
    currentPage: 0,
    currentSort: {
      headerIndex: 1,
      direction: 'descending',
    },
  }),
  computed: {
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('finances', ['pendingTransfers', 'totalOfPendingTransfers']),
    ...mapState('finances', ['totalAmount']),
  },
  methods: {
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('finances', ['getTransactionsToTransfers']),
    showModal() {
      this.openModal = !this.openModal;
    },
    async handleChangeRetailer() {
      await this.fillTransfers();
    },
    handleSidenavOpen() {
      this.sidenavOpen = !this.sidenavOpen;
    },
    async handleStatusSelect(newValue) {
      if (newValue === this.channelSelected) return;
      this.channelSelected = newValue;
      await this.fillTransfers();
    },
    async filterHandleStatusSelect(newValue) {
      if (newValue === this.filterStatusSelected) return;
      this.filterStatusSelected = newValue;
    },
    handleDatePick() {
      if (this.selectedDates.length === 2) this.fillTransfers();
    },
    toggleShowFilters() {
      this.showFilters = !this.showFilters;
    },
    handleSortTransfers(type) {
      const sortValue = Object.values(this.sort)[0];

      if (sortValue === -1) {
        this.sort = { [type]: 1 };
      } else {
        this.sort = { [type]: -1 };
      }
      this.fillTransfers();
    },
    handleSortTransfersIcon(type) {
      const sortValue = Object.values(this.sort)[0];
      const sortKey = Object.keys(this.sort)[0];

      let sortPic = 'sort.svg';

      if (type === sortKey && sortValue === 1) {
        sortPic = 'sort-asc.svg';
      } else if (type === sortKey && sortValue === -1) {
        sortPic = 'sort-desc.svg';
      }
      return sortPic;
    },
    showConfigDrawer() {
      this.openConfigDrawer = !this.openConfigDrawer;
    },
    handleNameMaxLength(name) {
      if (name.length > 32) {
        return `${name.substring(0, 32)}...`;
      }
      return name;
    },
    productsNameHandler(transactionData) {
      const totalOfProducts = transactionData.products.length || 0;
      if (totalOfProducts > 1) {
        return `${totalOfProducts} produtos`;
      }
      return this.handleNameMaxLength(transactionData.productName);
    },
    getImageUrl(transferData) {
      const { products } = transferData;
      if (products[0]?.image && products.length === 1) {
        return products[0].image;
      }
      return 'https://cdn.parcelex.com.br/assets/v3/icons/oder-placeholder-image.png';
    },
    async applyFilter() {
      if (this.channelSelected !== this.filterStatusSelected) {
        this.channelSelected = this.filterStatusSelected;
      }
      await this.fillTransfers();
      this.openConfigDrawer = false;
    },
    async fillTransfers() {
      const [startDate, endDate] = this.selectedDates;
      if (
        this.currentRetailer
        && this.currentRetailer._id
        && startDate instanceof Date
        && endDate instanceof Date
      ) {
        this.searchingTransfers = true;
        await this.getTransactionsToTransfers({
          startDate: moment(new Date(startDate)).startOf('day').toISOString(),
          endDate: moment(new Date(endDate)).endOf('day').toISOString(),
          retailerId: this.currentRetailer?._id,
          search: this.searchInput,
          limit: this.transfersPerPage,
          offset: this.currentPage,
          channel: this.channelSelected,
          sort: this.sort,
        });
        this.searchingTransfers = false;
      }
    },
    async filterBySearch(type) {
      this.filterBySearchType = type;
      await this.fillTransfers();
    },
    waitTypingFinish() {
      if (this.currentSearchTimeout) clearTimeout(this.currentSearchTimeout);

      this.currentSearchTimeout = setTimeout(() => {
        if (this.filterBySearchType === 'enter') {
          this.filterBySearchType = null;
          return;
        }
        this.filterBySearch('timeout');
      }, this.maxSearchTimeout);
    },
  },
  async mounted() {
    if (this.currentRetailer && this.currentRetailer._id) {
      this.selectedDates = [moment().subtract(1, 'month').toDate(), moment().toDate()];
      await this.setIsLoading({ isLoading: true });
      await this.fillTransfers();
      await this.setIsLoading({ isLoading: false });
    }
  },
  async beforeMount() {
    this.defaultDate = [
      moment().subtract(1, 'month').format('DD/MM/YYYY'),
      moment().format('DD/MM/YYYY'),
    ];
  },
};
</script>
